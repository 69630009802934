import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {ITicket} from 'src/app/interfaces/general/profile-definitions/ticket.interface';
import {ItemPayload} from 'src/app/interfaces/general/responses/item-payload.interface';
import {ParamsEncoder} from 'src/app/core/config/params-encoder';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TicketsService {
    private nextPageToken = null;
    constructor(
        private http: HttpClient,
        private config: ConfigurationService
    ) {}

    public getTickets(scope: 'CurrentAndFuture' | 'Past' | 'All' = 'All', nextPageToken?: string): Observable<ItemPayload<ITicket>> {
        let params = new HttpParams({
            encoder: new ParamsEncoder()
          }).append('scope', scope).append('top', 10000);
        if (nextPageToken) {
            params = params.append('continuationToken', nextPageToken);
        }
        return this.http.get<ItemPayload<ITicket>>(
            `${this.config.get('profileApiUrl')}/tickets`,
            {params}
        ).pipe(
            map((res) => {
                const data = [...res.data ?? []].sort((a, b) => {
                    const aDate = new Date(a.createdDateTime).getTime();
                    const bDate = new Date(b.createdDateTime).getTime();
                    return bDate - aDate;
                });
                return {...res, data};
            })
        );
    }

    public getValidTickets(nextPageToken?: string): Observable<ItemPayload<ITicket>> {
        return this.getTickets('CurrentAndFuture', nextPageToken);
    }

    public getPastTickets(nextPageToken?: string): Observable<ItemPayload<ITicket>> {
        return this.getTickets('Past', nextPageToken);
    }

    public getTicketById(id: string): Observable<ITicket> {
        return this.http.get<ITicket>(
            `${this.config.get('profileApiUrl')}/tickets/${id}`
        );
    }

    public getToken(): Observable<any> {
        return this.http.post<any>(
            `${this.config.get('profileApiUrl')}/token?grant_type=guest`,
            {}
        );
    }
}
